<template>
  <div class="wrap" ref="wraps" @mousemove="mouseOver"></div>
</template>
<script>
import * as THREE from 'three'
// import { constructor, update } from './js/romaUtil'
export default {
  name: 'threejs',
  data () {
    return {
      scene: null,
      camera: null,
      renderer: null,
      geometry: null,
      material: null,
      cube: null,
      clientX: null,
      clientY: null,
      CubeTextureLoader1: null,
      material6: null
    }
  },
  methods: {
    create () {
      this.camera = new THREE.PerspectiveCamera(
        75, window.innerWidth / window.innerHeight, 0.1, 1000
      )
      this.camera.position.z = 5
      this.scene = new THREE.Scene()
      this.material6 = new THREE.MeshLambertMaterial('./img/1994344283.png')
      this.geometry = new THREE.BoxGeometry(0.5, 0.5, 1.5)
      this.material = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube = new THREE.Mesh(this.geometry, this.material)
      this.cube1 = new THREE.Mesh(this.geometry, this.material)
      this.light = new THREE.AmbientLight(0xadadad)
      this.scene.add(this.light)
      this.cube.position.set(-5, 1.5, 0)
      this.cube1.position.set(5, 1.5, 0)
      this.scene.add(this.cube)
      this.scene.add(this.cube1)
      // this.scene.background = new THREE.CubeTextureLoader().setPath('./ambient/img1/').load(['posx.jpg', 'negx.jpg', 'posy.jpg', 'negy.jpg', 'posz.jpg', 'negz.jpg'])
      this.CubeTextureLoader1 = new THREE.CubeTextureLoader().setPath('ambient/img1/').load(['posx.jpg', 'negx.jpg', 'posy.jpg', 'negy.jpg', 'posz.jpg', 'negz.jpg'])
      // this.scene.background = new THREE.Color('red')
      this.scene.background = this.CubeTextureLoader1
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.$refs.wraps.appendChild(this.renderer.domElement)
    },
    animate () {
      requestAnimationFrame(this.animate)
      this.cube.rotation.y += 0.01
      this.cube1.rotation.y += 0.01
      this.initControls()
      this.renderer.render(this.scene, this.camera)
    },
    initControls () {
      this.rotateAngle = Math.PI
      this.actualrotateSpeed = (2 / 180) * Math.PI
      this.actualMoveSpeed = 0.1
      document.onkeydown = () => (
        this._onKeyDown()
      )
      document.onkeyup = () => (
        this._onKeyUp()
      )
    },
    _onKeyDown () {
      switch (window.event.keyCode) {
        case 87:
          this.moveForward = true
          this.move()
          break
        case 65:
          this.moveLeft = true
          this.move()
          break
        case 83:
          this.moveBackward = true
          this.move()
          break
        case 68:
          this.moveRight = true
          this.move()
          break
        case 81:
          this.rotateLeft = true
          this.move()
          break
        case 69:
          this.rotateRight = true
          this.move()
          break
        case 90:
          this.rotateUp = true
          this.move()
          break
        case 67:
          this.rotateDown = true
          this.move()
          break
      }
    },
    _onKeyUp () {
      switch (window.event.keyCode) {
        case 87:
          this.moveForward = false
          this.move()
          break
        case 65:
          this.moveLeft = false
          this.move()
          break
        case 83:
          this.moveBackward = false
          this.move()
          break
        case 68:
          this.moveRight = false
          this.move()
          break
        case 81:
          this.rotateLeft = false
          this.move()
          break
        case 69:
          this.rotateRight = false
          this.move()
          break
        case 90:
          this.rotateUp = false
          this.move()
          break
        case 67:
          this.rotateDown = false
          this.move()
          break
      }
    },
    move () {
      if (this.moveForward) this.camera.translateZ(-this.actualMoveSpeed)
      if (this.moveBackward) this.camera.translateZ(this.actualMoveSpeed)
      if (this.moveLeft) this.camera.translateX(-this.actualMoveSpeed)
      if (this.moveRight) this.camera.translateX(this.actualMoveSpeed)
      if (this.rotateLeft) {
        this.rotateAngle += this.actualrotateSpeed
        this.camera.rotateY(this.actualrotateSpeed)
      }
      if (this.rotateRight) {
        this.rotateAngle -= this.actualrotateSpeed
        this.camera.rotateY(-this.actualrotateSpeed)
      }
      if (this.rotateUp) {
        this.camera.translateY(this.actualMoveSpeed)
      }
      if (this.rotateDown) {
        this.camera.translateY(-this.actualMoveSpeed)
      }
    },
    mouseOver (event) {
      if (this.clientX == null) {
        this.clientX = event.clientX
      } else {
        let movex = 0
        movex = event.clientX - this.clientX
        this.camera.rotateY(-movex / 100)
        this.clientX = event.clientX
      }
      if (this.clientY == null) {
        this.clientY = event.clientY
      } else {
        let movey = 0
        movey = event.clientY - this.clientY
        this.camera.rotateX(-movey / 100)
        this.clientY = event.clientY
      }
    }
  },
  mounted () {
    this.create()
    this.animate()
  }
}
</script>
<style lang="stylus">
*
  margin 0
.wrap
  width 100vw
  height 100vh
  overflow hidden
canvas
  width 100% !important
  height 100% !important
</style>
